@charset "UTF-8";
/* ==========================================================================
HTML5 display definitions
========================================================================== */
/*
* Corrects `block` display not defined in IE 6/7/8/9 and Firefox 3.
*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

/*
* Corrects `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
*/
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

/*
* Prevents modern browsers from displaying `audio` without controls.
* Remove excess height in iOS 5 devices.
*/
audio:not([controls]) {
  display: none;
  height: 0;
}

/*
* Addresses styling for `hidden` attribute not present in IE 7/8/9, Firefox 3, * and Safari 4.
* Known issue:no IE 6 support.
*/
[hidden] {
  display: none;
}

body {
  background: white;
}

/* ==========================================================================
Base
========================================================================== */
/*
* 1. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
*    `em` units.
* 2. Prevents iOS text size adjust after orientation change, without disabling
*    user zoom.
*/
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
button {
  font-family: "方正兰亭黑", "Microsoft YaHei", "微软雅黑", "STXihei", "华文细黑", serif;
}

a {
  cursor: pointer;
}

/*
* Addresses `font-family` inconsistency between `textarea` and other form
* elements.
*/
html,
button,
input,
select,
textarea {
  font-family: "方正兰亭黑", "Microsoft YaHei", "微软雅黑", "STXihei", "华文细黑", serif;
}

/*
* Addresses margins handled incorrectly in IE 6/7.
*/
body {
  margin: 0;
  font-family: "方正兰亭黑", "Microsoft YaHei", "微软雅黑", "STXihei", "华文细黑", serif;
}

/*
* Font Smoothing
*/
html,
html a {
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -webkit-font-smoothing: antialiased;
}

/* ==========================================================================
Links
========================================================================== */
/*
* Addresses `outline` inconsistency between Chrome and other browsers.
*/
a:focus {
  outline: thin dotted;
  text-decoration: none;
}

/*
* Improves readability when focused and also mouse hovered in all browsers.
*/
a:active,
a:hover {
  outline: 0;
  text-decoration: none;
}

/* ==========================================================================
Typography
========================================================================== */
/*
* Addresses font sizes and margins set differently in IE 6/7.
* Addresses font sizes within `section` and `article` in Firefox 4+, Safari 5, * and Chrome.
*/
h1 {
  margin: 0.67em 0;
  font-size: 2em;
}

h2 {
  margin: 0.83em 0;
  font-size: 1.5em;
}

h3 {
  margin: 1em 0;
  font-size: 1.17em;
}

h4 {
  margin: 1.33em 0;
  font-size: 1em;
}

h5 {
  margin: 1.67em 0;
  font-size: 0.83em;
}

h6 {
  margin: 2.33em 0;
  font-size: 0.75em;
}

/*
* Addresses styling not present in IE 7/8/9, Safari 5, and Chrome.
*/
abbr[title] {
  border-bottom: 1px dotted;
}

/*
* Addresses style set to `bolder` in Firefox 3+, Safari 4/5, and Chrome.
*/
b,
strong {
  font-weight: bold;
}

blockquote {
  margin: 0px;
}

/*
* Addresses styling not present in Safari 5 and Chrome.
*/
dfn {
  font-style: italic;
}

/*
* Addresses styling not present in IE 6/7/8/9.
*/
mark {
  background: #ff0;
  color: #000;
}

/*
* Addresses margins set differently in IE 6/7.
*/
p,
pre {
  margin: 1em 0;
}

/*
* Corrects font family set oddly in IE 6, Safari 4/5, and Chrome.
*/
code,
kbd,
pre,
samp {
  font-size: 1em;
  font-family: "方正兰亭黑", "Microsoft YaHei", "微软雅黑", "STXihei", "华文细黑", serif;
  _font-family: 'courier new', monospace;
}

/*
* Improves readability of pre-formatted text in all browsers.
*/
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/*
* Addresses CSS quotes not supported in IE 6/7.
*/
q {
  quotes: none;
}

/*
* Addresses `quotes` property not supported in Safari 4.
*/
q:before,
q:after {
  content: '';
  content: none;
}

small {
  font-size: 75%;
}

/*
* Prevents `sub` and `sup` affecting `line-height` in all browsers.
*/
sub,
sup {
  position: relative;
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* ==========================================================================
Lists
========================================================================== */
/*
* Addresses margins set differently in IE 6/7.
*/
dl,
menu,
ol {
  margin: 1em 0;
}

dd {
  margin: 0 0 0 40px;
}

/*
* Addresses paddings set differently in IE 6/7.
*/
menu,
ol,
ul {
  padding: 0;
}

/*
* Corrects list images handled incorrectly in IE 7.
*/
nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

/* ==========================================================================
Embedded content
========================================================================== */
/*
* 1. Removes border when inside `a` element in IE 6/7/8/9 and Firefox 3.
* 2. Improves image quality when scaled in IE 7.
*/
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}

/*
* Corrects overflow displayed oddly in IE 9.
*/
svg:not(:root) {
  overflow: hidden;
}

/* ==========================================================================
Figures
========================================================================== */
/*
* Addresses margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
*/
figure {
  margin: 0;
}

/* ==========================================================================
Forms
========================================================================== */
/*
* Corrects margin displayed oddly in IE 6/7.
*/
form {
  margin: 0;
}

/*
* Define consistent border, margin, and padding.
*/
fieldset {
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
  border: 1px solid #c0c0c0;
}

/*
* 1. Corrects color not being inherited in IE 6/7/8/9.
* 2. Corrects text not wrapping in Firefox 3.
* 3. Corrects alignment displayed oddly in IE 6/7.
*/
legend {
  padding: 0;
  border: 0;
  /* 1 */
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */
}

/*
* 1. Corrects font size not being inherited in all browsers.
* 2. Addresses margins set differently in IE 6/7, Firefox 3+, Safari 5, *    and Chrome.
* 3. Improves appearance and consistency in all browsers.
*/
button,
input,
select,
textarea {
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  font-size: 100%;
  /* 1 */
  *vertical-align: middle;
  /* 3 */
}

/*
* Addresses Firefox 3+ setting `line-height` on `input` using ` !important` in
* the UA stylesheet.
*/
button,
input {
  line-height: normal;
}

/*
* 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
*    and `video` controls.
* 2. Corrects inability to style clickable `input` types in iOS.
* 3. Improves usability and consistency of cursor style between image-type
*    `input` and others.
* 4. Removes inner spacing in IE 7 without affecting normal text inputs.
*    Known issue:inner spacing remains in IE 6.
*/
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  /* 3 */
  -webkit-appearance: button;
  /* 2 */
  *overflow: visible;
  /* 4 */
}

/*
* Re-set default cursor for disabled elements.
*/
button[disabled],
input[disabled] {
  cursor: default;
}

/*
* 1. Addresses box sizing set to content-box in IE 8/9.
* 2. Removes excess padding in IE 8/9.
* 3. Removes excess padding in IE 7.
*    Known issue:excess padding remains in IE 6.
*/
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */
}

/*
* 1. Addresses `appearance` set to `searchfield` in Safari 5 and Chrome.
* 2. Addresses `box-sizing` set to `border-box` in Safari 5 and Chrome
*    (include `-moz` to future-proof).
*/
input[type="search"] {
  /* 2 */
  box-sizing: content-box;
  -webkit-appearance: textfield;
  /* 1 */
  nowhitespace: afterproperty;
}

/*
* Removes inner padding and search cancel button in Safari 5 and Chrome
* on OS X.
*/
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
* Removes inner padding and border in Firefox 3+.
*/
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/*
* 1. Removes default vertical scrollbar in IE 6/7/8/9.
* 2. Improves readability and alignment in all browsers.
*/
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/* ==========================================================================
Tables
========================================================================== */
/*
* Remove most spacing between table cells.
*/
table {
  border-spacing: 0;
  border-collapse: collapse;
}

/* ==========================================================================
Global Styles
========================================================================== */
.group:after {
  content: "";
  display: table;
  clear: both;
}

a {
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-property: color, border-color, background-color;
  transition-property: color, border-color, background-color;
}

.nopadding {
  margin: 0 !important;
  padding: 0 !important;
}

p {
  font-size: 14px;
  line-height: 25px;
}

a {
  color: black;
}

a:hover,
a:focus {
  color: black;
  text-decoration: none;
}

.texture-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-image: url(../images/grid.png);
}

/* ==========================================================================
form input is-focused color changed
========================================================================== */
.form-group.is-focused .form-control {
  background-image: linear-gradient(#030951, #030951), linear-gradient(#D2D2D2, #D2D2D2);
}

#portrait-template, #portrait-items {
  display: none;
}

#portrait {
  float: right;
  margin-top: 5px;
}

#portrait .material-icons {
  font-size: 24px;
}

#main {
  width: 100%;
  height: 100%;
}

.navbar .popover-content {
  padding: 0;
  margin-left: 35px;
}

.navbar .popover-content .dropdown-menu {
  margin-top: 0;
}

.language {
  cursor: auto;
  color: #CCC;
}

.language:hover {
  color: #CCC;
}

.language.enable {
  color: #080058;
  cursor: pointer;
}

.banner {
  width: 100%;
  height: 300px;
  background-image: url("../images/ushinef-pattern-2.svg");
  background-size: cover;
  padding: 85px 0;
  text-align: center;
}

.banner h1 {
  color: white;
  font-size: 60px;
  font-weight: 600;
  margin: 15px 0;
}

.banner p {
  color: white;
}

.banner .border {
  width: 500px;
  display: inline-block;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
}

footer {
  border-top: 1px solid #CCC;
}

iframe.edite {
  width: 100%;
  overflow: hidden;
  border: 0;
}

iframe.edite::-webkit-scrollbar {
  display: none;
}

.sidebar {
  background-color: #080047;
  border-radius: 4px;
  margin-top: 100px;
  position: relative;
  width: 150px;
  z-index: 1000;
  text-align: left;
  box-shadow: 0 6px 10px 0 rgba(255, 255, 255, 0.14), 0 1px 18px 0 rgba(255, 255, 255, 0.12), 0 3px 5px -1px rgba(255, 255, 255, 0.2);
  padding-bottom: 4px;
}

.sidebar .title {
  font-family: "方正兰亭黑", "Microsoft YaHei", "微软雅黑", "STXihei", "华文细黑", serif;
  font-size: 1.1em;
  color: white;
  margin: 0;
  line-height: 2.8em;
  padding-left: 1em;
}

.sidebar .items .categray p {
  margin: 0;
  cursor: pointer;
  font-weight: 900 !important;
  line-height: 2.5em;
  color: white;
}

.sidebar .items .categray p:hover {
  background: #424673;
  box-shadow: inset 0px 0px 2px #424673;
}

.sidebar .items .categray .categray-wrapper {
  display: none;
}

.sidebar .items .categray.active p {
  background: #424673;
  box-shadow: inset 0px 0px 2px #424673;
}

.sidebar .items .categray.open .categray-wrapper {
  display: block;
}

.sidebar .items .item {
  line-height: 2.5em;
  padding-left: 1em;
  transition: all 0.2s linear;
  cursor: pointer;
}

.sidebar .items .item.active, .sidebar .items .item:hover {
  background: #424673;
  box-shadow: inset 0px 0px 2px #424673;
}

.sidebar .items .item a {
  color: white;
}

.edite-list .card .card-image {
  max-height: 220px;
}

.edite-list .card-description {
  max-height: 5em;
  overflow: hidden;
}

.edite-detail .title {
  border-bottom: 1px solid #ddd;
  padding: 0.67em 0;
}

.edite-detail .time {
  width: 100%;
  padding-bottom: 0;
  text-align: right;
}

#ContractModal p:not(.first-line) {
  text-indent: 2em;
}

#ContractModal .modal-footer a.btn {
  margin: 0;
}

#ContractModal .modal-footer {
  padding: 20px;
}

.btn.btn-primary, .btn.btn-primary:hover, .btn.btn-primary:focus, .btn.btn-primary:active, .btn.btn-primary.active, .btn.btn-primary:active:focus, .btn.btn-primary:active:hover, .btn.btn-primary.active:focus, .btn.btn-primary.active:hover, .open > .btn.btn-primary.dropdown-toggle, .open > .btn.btn-primary.dropdown-toggle:focus, .open > .btn.btn-primary.dropdown-toggle:hover, .navbar .navbar-nav > li > a.btn.btn-primary, .navbar .navbar-nav > li > a.btn.btn-primary:hover, .navbar .navbar-nav > li > a.btn.btn-primary:focus, .navbar .navbar-nav > li > a.btn.btn-primary:active, .navbar .navbar-nav > li > a.btn.btn-primary.active, .navbar .navbar-nav > li > a.btn.btn-primary:active:focus, .navbar .navbar-nav > li > a.btn.btn-primary:active:hover, .navbar .navbar-nav > li > a.btn.btn-primary.active:focus, .navbar .navbar-nav > li > a.btn.btn-primary.active:hover, .open > .navbar .navbar-nav > li > a.btn.btn-primary.dropdown-toggle, .open > .navbar .navbar-nav > li > a.btn.btn-primary.dropdown-toggle:focus, .open > .navbar .navbar-nav > li > a.btn.btn-primary.dropdown-toggle:hover {
  background-color: #050555 !important;
  color: #FFFFFF !important;
}

.btn.btn-primary, .navbar .navbar-nav > li > a.btn.btn-primary {
  box-shadow: 0 2px 2px 0 rgba(5, 5, 85, 0.14), 0 3px 1px -2px rgba(5, 5, 85, 0.2), 0 1px 5px 0 rgba(5, 5, 85, 0.12);
}

.btn.btn-primary:hover, .navbar .navbar-nav > li > a.btn.btn-primary:hover {
  box-shadow: 3px 8px 8px 0 rgba(5, 5, 85, 0.25), 0 3px 1px -2px rgba(5, 5, 85, 0.35), 0 1px 5px 0 rgba(5, 5, 85, 0.25);
}

.dropdown-menu li > a:hover {
  background-color: #050555 !important;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  background-color: #050555 !important;
  color: #FFFFFF !important;
  box-shadow: 0 16px 26px -10px rgba(5, 5, 85, 0.56), 0 4px 25px 0px rgba(5, 5, 85, 0.12), 0 8px 10px -5px rgba(5, 5, 85, 0.2);
}

@media (max-width: 768px) {
  .banner {
    background-image: url("../images/ushinef-pattern-2.jpg");
  }
  .navbar.navbar-transparent {
    background-color: transparent;
  }
  .navbar .popover-content {
    padding: 0;
    margin-right: 180px;
  }
  .footer .social-buttons li {
    margin: 0 1em;
  }
  #portrait {
    margin-right: 20px;
  }
}

@media (max-width: 414px) {
  .banner {
    height: 180px;
    padding-top: 40px;
  }
  .banner .border {
    width: 250px;
    border: 0;
  }
  .banner h1 {
    font-size: 24px;
  }
}

@media (min-width: 414px) and (max-width: 768px) {
  .banner {
    height: 200px;
    padding: 40px 0;
  }
  .banner .border {
    width: 350px;
  }
  .banner h1 {
    font-size: 36px;
  }
}

@media (min-width: 768px) {
  .banner {
    height: 250px;
    padding: 70px 0;
  }
  .banner h1 {
    font-size: 50px;
  }
  .banner .border {
    width: 450px;
  }
}

.no-borderradius .banner .Logo {
  padding: 6px 0 0 10px;
  background: none;
}
